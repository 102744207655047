<template>
    <div class=" feature-blog-two" id="Actualites">
        <div class="add-padding">
            <div class="row">
                <div class="text-center" id="containerFlex">
                    <div class="title-style-one text-center ">
                        <h2 class="titre-section">
                            <span class="span-background-red">
                                les actualités
                            </span>
                        </h2>
                    </div>
                    <div class="box-actu d-flex">
                        <div v-for="actu in actus" class="flex-1">
                            <a class="global-link"
                                :href="`/actualites/${actu.titleA.replace(/\s/g, '_').replace(/\-/g, '_').toLowerCase()}-${actu.idA}`">
                                <div class="post-meta">
                                    <p class="cat"></p>
                                    <img :src='imageUrl(actu.idA, actu.siteIdA, actu.pictureThumbA)' :alt="actu.titleA"
                                        v-if="actu.pictureThumbA.length > 0">
                                    <img src='/images/icon/person_icon.png' :alt="actu.titleA" v-else>
                                    <div class="content">
                                        <h3 class="card-title made">
                                            {{ actu.titleA }}
                                        </h3>
                                        <p class="info-post  mt-15">
                                            {{ formatDescription(actu.titleSubA) }}
                                        </p>
                                        <div class="btnReadMore mt-25">
                                            <a class="button-more"
                                                :href="`/actualites/${actu.titleA.replace(/\s/g, '_').replace(/\-/g, '_').toLowerCase()}-${actu.idA}`">
                                                <i class="fa-solid fa-plus"></i> Lire l'article
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <br>
                    <a class="btn-transparent" href="/actualites">Toute l'actualité <i
                            class="fa-solid fa-arrow-right"></i></a>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'

const actus = ref([])

onMounted(async () => {
    const response = await axios({
        method: 'post',
        url: 'https://ws2-gbbu02.graphibox.eu/EpArticles.ws',
        data: { pKey: 'EmPA5d6sdse5z6ldfjPAEhazozp5ksJEZkzn872', pFct: 'GetArticles', pIdCat: 66, pNbItems: 3, pInHomePage: true },
    })
    actus.value = response.data
})

function formatDescription(description) {
    if (description.length > 200) {
        return description.substring(0, 200) + '...';
    }
    return description;
}
</script>

<style scoped>
.made {
    min-height: 7.5vh;
}

.info-post {
    min-height: 17.5vh;
    font-size: 16px;
    text-align: center;
}

.btn-transparent:hover {
    background-color: #25233E;
    color: #FFF;
}

.titre-section {
    color: var(--accent, #25233E);
    text-align: center;
    font-family: Nunito;
    font-size: 3.125rem;
    /*50 px */
    font-style: normal;
    font-weight: 600;
    line-height: 111.5%;
    letter-spacing: -0.825px;
    text-transform: uppercase;
}

.feature-blog-two {
    margin-top: 60px;
}

.flex-1 {
    margin-right: 30px
}

img {
    border-radius: 15px;
}

.feature-blog-two {
    padding: 3rem 0;
}

.global-link h2 a {
    height: 80px;
}

.title-style-one p {
    font-size: 19px;
}

.feature-blog-two .post-meta {
    /* height: 550px; */
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 400px;
}

.card-title {
    font-size: 28px;
    text-align: center;
    margin-top: 1rem;
    /* font-family: 'mountain', sans-serif; */
}

.button-more {
    color: var(--rouge);
    border: 2px solid var(--rouge);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-transform: uppercase;
    width: fit-content;
    padding: 10px;
    border-radius: 25px;
    margin: auto;
}

.button-more i {
    transition: 0.7s all;
}

.button-more:hover {
    background-color: var(--rose);
}

.button-more:hover i {
    transform: rotate(360deg);
}

.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-evenly;
}

.post-meta img {
    max-width: 360px;
}

.d-flex {
    justify-content: center;
}

.post-meta p {
    margin: 1rem;
}

.btn-actu {
    background-color: var(--bleu-fonce);
    border: 2px solid var(--bleu-fonce);
    color: white !important;
    padding: 10px 20px !important;
    border-radius: 35px;
}

.btn-actu {
    background-color: transparent;
    border: 2px solid var(--bleu-fonce);
    color: var(--bleu-fonce) !important;
}

.btn-transparent {
    display: block;
    margin: auto;
}

/* Styles pour les écrans de petite taille (S) */
@media only screen and (max-width: 599px) {
    .section-padding {
        padding-bottom: 0;
        padding-top: 3rem;
    }

    .feature-blog-two .post-meta {
        max-width: 275px !important;
    }
}


@media screen and (max-width: 991px) {
    .box-actu {
        flex-direction: column;
    }

    .feature-blog-two .post-meta {
        height: auto;
    }

    .flex-1 {
        width: 100%;
        display: block;
        margin: auto;
    }

    .info-post {
        max-height: unset !important;
    }

}

@media screen and (max-width: 600px) {
    .feature-blog-two .post-meta {
        max-width: 290px;
    }

    .card-title {
        font-size: 25px;
        margin-top: 15px;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
    .section-padding {
        padding-bottom: 0;
        padding-top: 3rem;
    }

    .feature-blog-two .post-meta {
        max-width: 275px !important;
    }
}

@media screen and (width:1280px) {
    .flex-1 {
        margin-right: 15px;
    }
}
</style>